<template>
    <div>
        <el-dialog :title="title" :visible.sync="addUserVisible"  width="800px"
                   :before-close="handleCancel" :close-on-click-modal="false">
                   <el-form ref="addUserForm" :model="form" label-width="100px">
                      <el-form-item label="机器码：">
                          <el-input v-model="form.machineCode" autocomplete="off" clearable></el-input>
                      </el-form-item>
                      <el-form-item label="有效时间：">
                        <el-select v-model="form.selectedTime" placeholder="请选择">
                            <el-option label="3天" value="4"></el-option>
                            <el-option label="7天" value="5"></el-option>
                            <el-option label="一个月" value="1"></el-option>
                            <el-option label="三个月" value="2"></el-option>
                            <el-option label="一年" value="3"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="申请原因：">
                          <el-input type="textarea" v-model="form.reason" autocomplete="off"></el-input>
                      </el-form-item>
                  </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="open(form)">提交申请</el-button>
                <el-button type="info" @click="handleCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'Adddesignerapply',
  props: {
    addUserVisible: Boolean,
    data: [Object, Boolean],
    title: String
  },
  data () {
    return {
      form: {
        machineCode: '',
        reason: '',
        selectedTime: '',
      }
    }
  },
  methods: {
    // 关闭弹窗
    handleCancel () {
      this.$emit('cancel')
      this.form.machineCode = ''
      this.form.reason = ''
      this.form.selectedTime = ''
    },
    open (form) {
      if (form.machineCode === '') {
        this.$message({
          type: 'info',
          message: '请输入机器码！'
        })
      } else if (form.reason === '') {
        this.$message({
          type: 'info',
          message: '请填写申请原因！'
      })
      } else if (form.selectedTime === '') {
        this.$message({
          type: 'info',
          message: '请选择有效时间！'
      })
      } else {
        this.$confirm('确认提交申请吗？', '提交申请', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
          // console.log('11111111',form.machineCode)
          this.axios.post('/users/insertlicense/', {
              userid: localStorage.getItem('user_id'),
              username: localStorage.getItem('yun_user'),
              unitid: localStorage.getItem('unit_id'),
              applycause: form.reason,
              machineCode: form.machineCode,
              licensetime: form.selectedTime
            }).then((res) => {
              this.$message({
                type: 'success',
                message: '提交申请成功，请等待管理员通过！'
              })
              this.form.machineCode = ''
              this.form.reason = ''
              this.$emit('cancel')
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消提交申请'
          })
        })
      }
    }
  }
}
</script>

<style scoped>
     .Attopic{display:block; left: 56px; position: absolute; top: 68px; z-index: 999; background: #fff; border: 1px solid #ccc; padding: 2px; width: 180px; height: 100px; overflow-x:hidden; overflow-y:hidden;}

</style>
